import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const frontmatter = {
  title: "Goals for 2015 and what happened in 2014",
  date: '2015-01-05T22:58:59.501Z',
  draft: false
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Beginning this year I'm starting an Annual Review - a report of my reflections on the last year and what I plan to do on the year to come. This is a way to hold myself accountable to the world as well as celebrate my successes throughout the year.`}{
        /*more*/
      }<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <h2>{`What went well`}</h2>
    <p><strong parentName="p">{`Finding a Mentor.`}</strong>{` Although I believe strongly in self education, I don't believe learning happens in a bubble. That's why I sought out someone who already knew how to program in order to teach me how to become an expert programmer. My mentor has helped me see what I don't know and brought me on a team of professionals to hone my skills. His help is continuing into the new year and it's pretty awesome.`}</p>
    <p><strong parentName="p">{`Discipline.`}</strong>{` In order to make any reasonable amount of progress, you need to have the grit to carry through your goals. It's been my major weakness and I'm making daily progress. Working on a team has held me accountable to produce work on a regular basis and instill this discipline. My work as become far more regular and less like a rollercoaster of productivity and slothfulness. It's still something I need to work on, but I still consider it a major victory for 2014.`}</p>
    <p><strong parentName="p">{`Unashamed.`}</strong>{` Last year I `}<a parentName="p" {...{
        "href": "http://donniewest.com/i-want-to-code/"
      }}>{`publicly declared`}</a>{` that I was ashamed of being in fast food and incessantly afraid of failure. This forced me to confront it and realize it's a major factor that has been holding me back from accomplishing all that I could be. Instead of being ashamed on my job, I forced myself to dwell on the fact that it's my grit that matters. Instead of being without a degree, I'm debt free. Losing my shame and fear has been a major victory for 2014.`}</p>
    <h2>{`What didn't go well`}</h2>
    <p><strong parentName="p">{`Seinfeld Method.`}</strong>{` Last year I had the intent to program daily - any day I did so, I just marked an X on the calendar and sought to not break the chain of X's. It was an impossible goal: I have a full time job, a wife and 3 kids. I just simply don't have the time! In many ways though I wasted my time unnecessarily and was not nearly as focused as I could have been. Wasting time has been a major weakness of mine and I need to become more laser focused on my goals.`}</p>
    <h2>{`What I'm working toward`}</h2>
    <p><strong parentName="p">{`Job.`}</strong>{` I have been programming regularly for almost two years now. Unfortunately, this has mostly resulted in toy projects that aren't real showcases of my knowledge or private team projects that cannot be shared with the world. It's time to set aside the toys and become a professional programmer this year, even if it means I have to become an entrepreneur and employ myself.`}</p>
    <p><strong parentName="p">{`Metrics.`}</strong>{` While writing this article one of the biggest issues I had was the lack of real numbers on how well I've improved over the last year. In 2015 I want to begin quantifying where I am and how I've improved over the year. I will learn in the open with at-least-monthly blog posts to showcase that knowledge. Privately, I want to begin tracking my exercise and where my money goes so I can begin those incremental steps toward greatness.`}</p>
    <p><strong parentName="p">{`Family.`}</strong>{` As a Christian, husband and father I am the spiritual leader of my home. Through that leadership, I need to build my marriage and my family. This year I plan to begin weekly family devotions and bring my children through a catechism so I know I'm doing my duty as both husband and father.`}</p>
    <p>{`While I have little idea of where 2015 will take me, I hope to steer myself toward a year of transformation with these guiding principles. The start of doing things right.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Yep, I stole this format blatently from the wonderful `}<a parentName="li" {...{
            "href": "http://jamesclear.com/2014-annual-review"
          }}>{`James Clear`}</a>{`. Go check him out.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      